import React from "react"
// import WebriqForm from "./form/form"
import WebriQForm from "@webriq/gatsby-webriq-form"

const DynamicForm = () => {
  return (
    <div className="page-form">
      <h2 className="p-3">Contact us for more information: </h2>
      <WebriQForm
        method="POST"
        name="Quotation Form"
        data-form-id="73da1494-2329-4ad3-bf0e-636051b0738d"
        data-thankyou-url="/thank-you"
        className="dynamic-form"
      >
        <div className="row p-3">
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="Name"
                placeholder="Name"
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <input
                className="form-control"
                type="email"
                name="Email"
                placeholder="Email"
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="subject"
                placeholder="Subject"
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <textarea
                className="form-control"
                name="message"
                placeholder="Message"
                rows="4"
              ></textarea>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <div className="webriq-recaptcha" />
            </div>
          </div>
          <div className="form-group col-12">
            <button type="submit" className="btn btn-primary btn-lg btn-red">
              <i className="fa fa-envelope mr-2 hvr-icon hvr-icon"></i>
              Send Message
            </button>
          </div>
          <div className="form-group col-12">
            <p>
              Or give us a call at: <a href="tel:303-466-7341">303-466-7341</a>
              <br />
              Monday - Friday, 7:00 AM - 5:00 PM, MST
            </p>
          </div>
        </div>
      </WebriQForm>
    </div>
  )
}

export default DynamicForm
