import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "@components/layout"
import SEO from "@components/seo"
import marked from "marked"
import DynamicForm from "../components/dynamicForm"
import PageHeadingTitle from "@components/pageHeadingTitle"

const UsedEquipmentTemplate = ({ data }) => {
  const { usedequipment } = data

  console.log("test", usedequipment)

  return (
    <Layout>
      <SEO title={usedequipment.name} description="{usedEquipment.excerpt}" />
      <div className="page-headline">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9">
              <PageHeadingTitle h1={"EQUIPMENT"} h2={usedequipment.name}/>
              {/* <span className="small">Posted on {post.publishedAt}</span> */}
            </div>
          </div>
        </div>
      </div>

      {usedequipment ? (
        <div className="page-body productType">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <Gallery images={usedequipment.gallery} />
              </div>
              <div className="col-md-6">
                {/* <div className="Capacity mb-5">
                  <table style={{ width: "100%" }}>
                    <h2>Capacities</h2>
                    {usedequipment.capacities.map(capacity => (
                      <tr>
                        <th>{capacity.name}</th>
                        <td>{capacity.value}</td>
                      </tr>
                    ))}
                  </table>
                </div> */}

                <div className="Specs">
                  <h2 className="text-uppercase mb-4">Item Specifics</h2>
                  <table style={{ width: "100%", margin: "1rem 0" }}>
                    <h2>Specifications</h2>
                    {usedequipment.specifications.map(specs => (
                      <tr>
                        <th>{specs.name}</th>
                        <td>{specs.value}</td>
                      </tr>
                    ))}
                  </table>
                  <h3>Price: {`$${usedequipment.price}`}</h3>
                </div>
              </div>
              <div className="mt-5">
                <div
                  dangerouslySetInnerHTML={{
                    __html: marked(usedequipment.body || ""),
                  }}
                />
              </div>
              <div className="container mt-5">
                <DynamicForm />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </Layout>
  )
}

const Gallery = ({ images }) => {
  const [mainImage, setMainImage] = useState(images[0])
  return (
    <div className="product-image-gallery">
      <div>
        <img
          className="img-fluid w-100"
          src={mainImage?.asset?.fluid?.src}
          alt="Gallery"
        />
      </div>
      <div className="d-flex flex-wrap align-items-center mt-3">
        {images.map((image, index) => (
          <img
            key={index}
            className="img-fluid mr-1 mb-1"
            src={image?.asset?.fluid?.src}
            style={{ maxHeight: 70, cursor: "pointer" }}
            onClick={() => setMainImage(image)}
            onKeyDown={() => setMainImage(image)}
            alt="Gallery Thumbnail"
            role="presentation"
          />
        ))}
      </div>
    </div>
  )
}

export default UsedEquipmentTemplate

export const usedEquipmentQuery = graphql`
  query UsedEquipmentTemplateQuery($id: String!) {
    usedequipment: sanityUsedequipment(id: { eq: $id }) {
      id
      name
      body
      price
      banner {
        asset {
          fluid {
            src
          }
        }
      }
      capacities {
        name
        value
      }
      gallery {
        asset {
          fluid {
            src
          }
        }
      }
      specifications {
        name
        value
      }
    }
  }
`
